import React from "react";

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for(let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <ul className="pagination">
                {pageNumbers.map(number => (
                    <li key={number} className={currentPage === number ? 'active' : null}>
                        <a href="#fig-scroll" onClick={() => paginate(number)}>
                            {number}
                        </a>
                    </li>
                ))}  
            </ul>
        </div>
    )
}

export default Pagination;
