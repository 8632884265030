import React, { useState, useEffect } from "react";
import '../assets/styles/CollectionList.scss';
import Pagination from './Pagination';
import ListItem from './ListItem';
import { API_URL, options } from '../utils/urls'

function CollectionList({ listType, endpoint, icon, totalFigs }) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [itemcount, setItemCount] = useState('');
    const [items, setItems] = useState([]);

    const getListCount = () => {
        fetch(API_URL(`users/5f5f193b6012baf7fb33eb7152c40268f0818dc8414a2a206b71c0dee8d8fdbe/${endpoint}`), options)
        // fetch('figdata.json') // Temporary API
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setItemCount(data.count);
            totalFigs(data.count);
        });
    };

    const fetchListData = () => {
        fetch(API_URL(`users/5f5f193b6012baf7fb33eb7152c40268f0818dc8414a2a206b71c0dee8d8fdbe/${endpoint}/?page_size=500`), options)
        // fetch('figdata.json') // Temporary API
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            setItems(data.results);
        });
    };

    useEffect(() => {
        getListCount();
        fetchListData();
    }, []);

    // Get current Posts
    const indexOfLastPost = currentPage * itemsPerPage;
    const indexOfFirstPost = indexOfLastPost - itemsPerPage;
    const current = items.slice(indexOfFirstPost, indexOfLastPost);

    // Change Page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Increase Page Count
    // function showMore() {
    //     setItemsPerPage(itemsPerPage => itemsPerPage + 10);
    // }

    function toggleFigCount() {
        if (itemsPerPage > items.length) {
            setItemsPerPage(20)
        } else {
            setItemsPerPage(items.length + 1)
        }
    }

    return (
        <section className="fig-description-section" id="fig-scroll">
            <img src={icon} className="icon" alt="Minifigure Icon"></img>
            <p className="headline">Total Figs: <span>{itemcount}</span></p>
            <div className="small-hr"></div>
            <p className="content">{itemcount} is the total number of minifigs that I have logged. It is a very small part of my whole collection. I'd estimate it is around 50% of the total number.</p>
            
            <div className="fig-list">
                {current.length > 0 && (
                    <ul className="figs">
                        {current.map((item, idx) => (
                            <ListItem item={item} id={idx} child={listType} />
                        ))}
                    </ul>
                )}
                {/* <button onClick={showMore}>Load More</button> */}
                <span className={itemsPerPage < items.length ? 'pagination-toggle' : 'pagination-toggle hide'}>
                    <Pagination itemsPerPage={itemsPerPage} totalItems={items.length} currentPage={currentPage} paginate={paginate} />
                </span>
                <button onClick={() => toggleFigCount()} className="toggle-total">
                    {itemsPerPage > items.length ? 'Show Less' : 'Show All'}
                </button>
            </div>
        </section>
    );
};

export default CollectionList;
