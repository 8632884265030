import './App.css';
import './assets/styles/App.scss';
import React, { useState } from "react";
import Ninety from "./assets/images/1990.jpg";
import Ought from "./assets/images/2000.jpg";
import Tens from "./assets/images/2010.jpg";
import Twenty from "./assets/images/2020.jpg";
import Figicon from "./assets/images/lego-head.svg";
import Seticon from "./assets/images/lego-brick.svg";
import Brikcoder from "./assets/images/brikcoder.svg";
import CollectionList from './components/CollectionList';
import CollectionByYear from './components/CollectionByYear';

function App() {
  const [isActive, setActive] = useState(0);
  const [figCount, setFigCount] = useState(0);
  const [setCount, setSetCount] = useState(0);

  const toggleType = (e) => {
    setActive(e)
  };

  const getTotalSets = (setNumber) => {
    setSetCount(setNumber)
  }

  const getTotalFigs = (figNumber) => {
    setFigCount(figNumber)
  }

  return (
    <div>
      <div className='hero'>
        <div>
          <img src={Brikcoder} alt="Brikcoder" />
          <h1>Interactive Collection</h1>
        </div>
      </div>
      <div className={isActive === 0 ? 'hero-buttons' : 'hero-buttons hide'}>
        <button onClick={() => toggleType(1)}>
          <span>
            <img src={Figicon} alt="Figs" />
            <p>Figs</p>
            <span>{figCount}</span>
          </span>
        </button>
        <button onClick={() => toggleType(2)}>
          <span>
            <img src={Seticon} alt="Sets" />
            <p>Sets</p>
            <span>{setCount}</span>
          </span>
        </button>
      </div>
      <section className={isActive === 2 ? 'main-sections show' : 'main-sections'} id='sets'>
        <CollectionByYear 
          totalSets={getTotalSets}
          endpoint='sets' 
          listType='set' 
          decade="1990's" 
          min={2000} 
          max={0}
          img={Ninety}
          headline='A Great Start!'
          body="With the Star Wars partnership starting in '99, the Rock Raiders and Western themes, and some great hand-me-down sets, it is hard to imagine a better time to start to my collection."
        />
        <CollectionByYear 
          totalSets={getTotalSets}
          endpoint='sets' 
          listType='set' 
          decade="2000's" 
          min={1999} 
          max={2010}
          img={Ought}
          headline="Begun the 'Dark Age' Has"
          body="This decade began with a bang as the Star Wars theme hit its stride, but just as the Clone Wars sets were in their hayday, I entered into my 'Dark Age'."
        />
        <CollectionByYear 
          totalSets={getTotalSets}
          endpoint='sets' 
          listType='set' 
          decade="2010's" 
          min={2009} 
          max={2020}
          img={Tens}
          headline="A Renewed Spirit"
          body="My good friend pulled me out of the 'Dark Age' by gifting me the Helm's Deep set. I discovered bricklink this decade and started designing my first MOCs."
        />
        <CollectionByYear 
          totalSets={getTotalSets}
          endpoint='sets' 
          listType='set' 
          decade="2020's" 
          min={0} 
          max={2019}
          img={Twenty}
          headline="The Collection Grows"
          body="The decade is still young so the results are still TBD."
        />
      </section>
      <section className={isActive === 1 ? 'main-sections show' : 'main-sections'} id="figs">
        <CollectionList endpoint='minifigs' listType='minifig' icon={Figicon} totalFigs={getTotalFigs} />
      </section>
      <button className={isActive !== 0 ? 'back-button' : 'back-button hide'} onClick={() => toggleType(0)}>
        &larr; Back to Selection
      </button>
    </div> 
  );
}

export default App;
