import React, { useState, useEffect } from "react";
import '../assets/styles/CollectionByYear.scss';
import ListItem from "./ListItem";
import { API_URL, options } from '../utils/urls'

function CollectionByYear({ endpoint, listType, decade, min, max, img, headline, body, totalSets }) {
    const [items, setItems] = useState([]);

    const fetchDataByYear = () => {
        fetch(API_URL(`users/5f5f193b6012baf7fb33eb7152c40268f0818dc8414a2a206b71c0dee8d8fdbe/${endpoint}/?page_size=500`), options)
        // fetch('setdata.json') // Temporary API
        .then((response) => {
            const legodata = response.json();
            console.log(legodata);
            return legodata;
        })
        .then((data) => {
            setItems(data.results);
            totalSets(data.results.length);
        });
    };

    const countFilter = (min, max) => {
        if (min !== 0 && max !== 0) {
            return items.filter(item => (item.set.year > min) && (item.set.year < max)).length
        } else if (min !== 0) {
            return items.filter(item => item.set.year < min).length
        } else if (max !== 0) {
            return items.filter(item => item.set.year > max).length
        }
    }

    const contentFilter = (min, max) => {
        if (min !== 0 && max !== 0) {
            return items
                .sort((a, b) => a.set.year < b.set.year ? -1 : 1)
                .filter(item => (item.set.year > min) && (item.set.year < max))
                .map((item, idx) => (
                    <ListItem item={item} id={idx} child={listType} />
                ))
        } else if (min !== 0) {
            return items
                .sort((a, b) => a.set.year < b.set.year ? -1 : 1)
                .filter(item => item.set.year < min)
                .map((item, idx) => (
                    <ListItem item={item} id={idx} child={listType} />
                ))
        } else if (max !== 0) {
            return items
                .sort((a, b) => a.set.year < b.set.year ? -1 : 1)
                .filter(item => item.set.year > max)
                .map((item, idx) => (
                    <ListItem item={item} id={idx} child={listType} />
                ))
        }
    }

    useEffect(() => {
        fetchDataByYear();
    }, []);

    return (
        <>
            {items.length > 0 && (
                <section className="decades">
                    <div className="decadeDescriptor">
                        <div className="image-holder">
                            <img src={img} alt="Favorite set of the Decade" />
                        </div>
                        <div className="content-holder">
                            <div>
                                <p className='headline'>{headline}</p>
                                <p className='body'>{body}</p>
                                <div className="detail-box">
                                    {decade}
                                    <span>{countFilter(min, max)} Sets</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="decadeHolder">
                        <ul>
                            {contentFilter(min, max)}
                        </ul>
                    </div>
                </section>
            )}
        </>
    );
};

export default CollectionByYear;
