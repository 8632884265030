const BASE_URL = 'https://rebrickable.com/api/v3/'

export const API_URL = (slug) => `${BASE_URL}/${slug}`

export const options = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    Authorization: 'key 8b91507940f6c37de85e2d41eaa1ef97'
  }
}