import React from "react";
import { convertTheme } from '../utils/themeSort';
import Figicon from "../assets/images/lego-head.svg";

function ListItem({ id, item, child }) {
    let content = '';

    if (child === 'set') {
        content = (
            <div>
                <div className="image-holder">
                    <p>{item.set.year}</p>
                    <img src={item.set.set_img_url} alt={item.set.name} />
                </div>
                <div className="content-holder">
                    <p className="headline" title={item.set.name}>{item.set.name}</p>
                    <div className="other-content">
                        <p>Theme: {convertTheme(item.set.theme_id)}</p>
                        <div className="detail-holder">
                            {item.set.set_num}
                            <span>{item.set.num_parts} Pieces</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else if (child === 'minifig') {
        if (item.minifig.set_img_url) {
            content = (
                <div>
                    <div className="fig-headline" title={item.minifig.name}>{item.minifig.name}</div>
                    <div className="image-holder">
                        <img src={item.minifig.set_img_url} alt={item.minifig.name} />
                        <p className="fig-details">
                            {item.minifig.set_num}
                        </p>
                    </div>
                </div>
            )
        } else {
            content = (
                <div>
                    <div className="fig-headline" title={item.minifig.name}>{item.minifig.name}</div>
                    <div className="image-holder">
                        <div className="placeholder-fig">
                            <img src={Figicon} alt="Placeholder" />
                        </div>
                        <p className="fig-details">
                            {item.minifig.set_num}
                        </p>
                    </div>
                </div>
            )
        }
    };

    return (
        <li key={id}>
            {content}
        </li>
    );
};

export default ListItem;
