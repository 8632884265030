export const convertTheme = (id) => {
    switch(id) {
        case 158:
        case 18:
        case 171:
            return 'Star Wars';
        case 67:
            return 'Classic Town';
        case 133:
            return 'Ice Planet 2002';
        case 475:
            return 'Western';
        case 144:
            return 'UFO';
        case 86:
            return 'Divers';
        case 134:
            return 'Insectoids';
        case 442:
            return 'Rock Raiders';
        case 246:
            return 'Harry Potter';
        case 135:
            return 'Life On Mars';
        case 324:
            return 'Bionicle';
        case 459:
            return 'Basketball';
        case 198:
            return 'Knights Kingdom II';
        case 706:
            return 'Spider-Man';
        case 537:
        case 540:
        case 541:
        case 542:
        case 545:
        case 712:
        case 715:
        case 743:  
        case 548:
        case 549:  
        case 745:
        case 551:
        case 677:
        case 681:
        case 692:
        case 714:
        case 718:
            return 'Collectible Minifigures';
        case 566:
            return 'The Lord of the Rings';
        case 705:
            return 'Iron Man';
        case 401:
            return 'Hero Factory';
        case 578:
            return 'The LEGO Movie';
        case 697:
            return 'Batman';
        case 700:
            return 'Justice League';
        case 610:
            return 'Brickheadz';
        case 721:
            return 'Icons';
        case 501:
            return 'Gear';
        case 576:
            return 'LEGO Ideas';
        case 598:
            return 'Promotional';
        case 577:
            return 'Minecraft';
        default:
            return 'N/A'
    }
}